import { Question, Response } from "./classes";
import moment from "moment";
import { decodeUrlPath } from "./utils";
import { permissionsType } from "@/apps/settings-config/types/settingsConfigTypes";

// Helper functions acting as the intermediary between the classes and the stores

export const getUserNameInitials = (name: string): string => {
  let words = name?.trim().split(" ");
  words = words?.length > 2 ? words.slice(0, 2) : words;
  return words?.map((word) => word[0]).join("") || "";
};

export const forMatNumbers = (number: string): string => {
  if (!number) return "";
  const numericOnly = number.trim().replace(/\D/g, "");
  if (numericOnly.startsWith("233")) {
    return `+233 ${numericOnly.substring(3, 6)} ${numericOnly.substring(6)}`;
  }
  if (numericOnly?.startsWith("0")) {
    return `+233 ${numericOnly.substring(1, 3)} ${numericOnly.substring(
      3,
      6
    )} ${numericOnly.substring(6)}`;
  }
  if (!number) return "";
  return `+233 ${numericOnly.substring(0, 2)} ${numericOnly.substring(
    2,
    6
  )} ${numericOnly.substring(6)}`;
};

export function getUserId(path: string) {
  const paths = path.split("=@");
  if (paths.length > 1) {
    return paths[1];
  }

  return decodeUrlPath(path);
}

// export function addCurrentQuestionnaire() {
//   const questionnaireStore = questionnaireList();
//   if (!questionnaireStore.currentquestionnaireId) {
//     const createdFormId = questionnaireStore.addQuestionnaire();
//     return createdFormId;
//   }
//   return "";
// }

// export function addQuestion() {
//   const questionnaireStore = questionnaireList();
//   const sampleQuestionObject = {
//     question: "",
//     optionsObject: { options_type: "Short paragraph", options: "" },
//   };
//   const sampleObjectSecond = {
//     question: "",
//     optionsObject: { options_type: "Short paragraph", options: "" },
//   };
//
//   if (!questionnaireStore.currentquestionnaireId) {
//     const createdFormId = addCurrentQuestionnaire();
//     const untitledQuestion = new Question(createdFormId, sampleQuestionObject);
//
//     questionnaireStore.addQuestion(
//       questionnaireStore.currentquestionnaireId,
//       untitledQuestion
//     );
//   } else {
//     const questionTemplate = new Question(
//       questionnaireStore.currentquestionnaireId,
//       sampleObjectSecond
//     );
//     if (questionnaireStore.editQuestionnaireMode) {
//       questionTemplate.questionnaire_group_id = Number(
//         questionnaireStore.currentquestionnaireId
//       );
//       questionnaireStore.addQuestion(
//         questionnaireStore.currentquestionnaireId,
//         questionTemplate
//       );
//     } else {
//       questionnaireStore.addQuestion(
//         questionnaireStore.currentquestionnaireId,
//         questionTemplate
//       );
//     }
//   }
// }
//
// export function deleteQuestion(questionId: string) {
//   const questionnaireStore = questionnaireList();
//
//   if (questionnaireStore.currentquestionnaireId) {
//     const returnedId = questionnaireStore.deleteQuestion(questionId);
//     if (returnedId) {
//       // return the question id after successfully deleting
//       return returnedId;
//     }
//   }
// }
//
// export function addAtPosition(questionId: string) {
//   const questionnaireStore = questionnaireList();
//
//   const duplicateQuestionId = questionnaireStore.duplicateQuestion(questionId);
//   // eslint-disable-next-line no-prototype-builtins
//   if (duplicateQuestionId) {
//     return duplicateQuestionId;
//   } else {
//     return "Not duplicated..";
//   }
// }

// add helper functions for the responses and completions of assessments
interface listOfQuestionObjectsType {
  id: number;
  qtid: number | string;
  competency_id: number;
  required_field: boolean;
}
// export function populateResponses(
//   listOfQuestionObjects: listOfQuestionObjectsType[]
// ) {
//   // logic for the creation of new response instances and adding to the responses store
//   const responsesStore = useResponsesStore();
//   // const questionnaireStore = questionnaireList()
//
//   for (const question of listOfQuestionObjects) {
//     const newReponseObject = new Response({
//       qtid: question.id,
//       competency_id: question.competency_id,
//       required_field: question.required_field,
//       response_data: "",
//       options_type: "",
//     });
//     responsesStore.addResponse(newReponseObject);
//   }
//   // if (listOfQuestionObjects.length === responsesStore.responses.length) {
//   //   return "Populated successfully";
//   // }
//   if (
//     Array.isArray(listOfQuestionObjects) &&
//     Array.isArray(responsesStore.responses) &&
//     listOfQuestionObjects.length === responsesStore.responses.length
//   ) {
//     return "Populated successfully";
//   }
// }

//date Formating
export function formatDate(date: string| Date) {
  return new Date(date).toISOString().split("T").splice(0, 1).join("") || "";
}

// time Formatting
export function formatTime(date: string) {
  const time = new Date(date).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true
  });
  return time || "";
}

//permission extraction
export function viewManage(permObject: permissionsType, key: string) {
  const newObject: permissionsType = {};
  for (const perm in permObject) {
    if (perm.slice(0, perm.indexOf("_")) == key) {
      newObject[perm] = permObject[perm];
    }
  }
  return newObject;
}

//permission check always call with viewManage returned data
export function permCheck(permObject: permissionsType, key: string) {
  let check: boolean;
  if (key == "all") {
    check = true;
    for (const perm in permObject) {
      check = permObject[perm] && check;
    }
    return check;
  } else if (key == "atLeastOne") {
    check = false;
    for (const perm in permObject) {
      check = permObject[perm] || check;
    }
    return check;
  } else {
    return false;
  }
}

export function formatAmount(value: number) {
  const val = (value / 1).toFixed(2).replace(",", ".");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formattedDate = (item: string, isUnix?: boolean) => {
  const dateObj = isUnix ? moment(parseInt(item)) : moment(item);
  const formattedDate = dateObj.format("Do MMMM YYYY");
  return formattedDate || "";
};

export function formattedTime(date: string, isUnix?: boolean) {
  const dateObj = isUnix ? moment(parseInt(date)) : moment(date);
  const formattedTime = dateObj.format("hh:mm A");
  return formattedTime || "";
}
///////////new functions for assessment

export function handleEmitEvent(
  parent: Array<{ id: number }> = [],
  child: { id: number },
  event: string
): Array<{ id: number }> {
  let data = [] as Array<{ id: number }>;
  switch (event) {
    case "update":
      data = [...parent].map((value) => {
        if (String(value.id) == String(child.id)) return child;
        return value;
      });
      break;
    case "create":
      data = [child, ...parent];
      break;
    case "delete":
      data = [...parent].filter(
        (value) => String(value.id) !== String(child.id)
      );
      break;
  }
  return data;
}

export function numberToWords(num: number): string {
  if (num === 0) {
    return "Zero";
  }
  const units = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine"
  ];
  const teens = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen"
  ];
  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety"
  ];
  function convertLessThanThousand(num: number) {
    let result = "";
    if (num >= 100) {
      result += units[Math.floor(num / 100)] + " Hundred";
      num %= 100;
      if (num > 0) {
        result += " and ";
      }
    }
    if (num >= 20) {
      result += tens[Math.floor(num / 10)];
      num %= 10;
      if (num > 0) {
        result += "-";
      }
    }
    if (num > 0) {
      if (num < 10) {
        result += units[num];
      } else {
        result += teens[num - 10];
      }
    }
    return result;
  }
  if (num < 0) {
    return "Negative " + numberToWords(-num);
  }
  let result = "";
  const billion = Math.floor(num / 1_000_000_000);
  const million = Math.floor((num % 1_000_000_000) / 1_000_000);
  const thousand = Math.floor((num % 1_000_000) / 1_000);
  const remainder = num % 1_000;
  if (billion > 0) {
    result += convertLessThanThousand(billion) + " Billion";
    if (million > 0 || thousand > 0 || remainder > 0) {
      result += ", ";
    }
  }
  if (million > 0) {
    result += convertLessThanThousand(million) + " Million";
    if (thousand > 0 || remainder > 0) {
      result += ", ";
    }
  }
  if (thousand > 0) {
    result += convertLessThanThousand(thousand) + " Thousand";
    if (remainder > 0) {
      result += ", ";
    }
  }
  if (remainder > 0) {
    result += convertLessThanThousand(remainder);
  }
  return result || "";
}

export function truncateText<T>(
  arrayOfObjects: {
    id: string;
    name: string;
    icon: string;
    component: T;
  }[]
): {
  id: string;
  name: string;
  icon: string;
  component: T;
}[] {
  const maxLength = 17;
  if (arrayOfObjects) {
    for (const obj of arrayOfObjects) {
      if (obj && obj.name) {
        obj.name =
          obj.name.length > maxLength
            ? obj.name.substring(0, maxLength) + "..."
            : obj.name;
      }
    }
  }
  return arrayOfObjects;
}

export function userChecker(
  currentUser: string | number,
  profileOwner: string,
  managerId:number,
  cdc_advisor_id:number
): string {
  switch (Number(currentUser)) {
    case Number(managerId):return "manager";
    case Number(cdc_advisor_id):return "cdc_advisor";
    case Number(decodeUrlPath(profileOwner)):return "personal";
    default: return "other_employees";
  }
}
