import { AnalyticsBrowser } from "@segment/analytics-next";

export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.VUE_APP_ANALYTICS
});

 type eventProps = {
  [key:string]: string
 }
export function  trackEvent(value: string, object:eventProps ) {
  analytics.track(value, object);
}
