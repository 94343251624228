import timeTrackerRoute from "@/apps/time-tracker/router/timeTrackerRoute";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import PageNotFound from "../apps/authentication/components/PageNotFound.vue";
import {
  decodeRouteToken,
  getToken,
  getUserid,
  isLoggedIn,
  logout,
  setToken
} from "../services/auth";
import { encodeUrlPath } from "@/helpers/utils";
import { useStore } from "@/store";

const route: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta: { auth: true },
    redirect: (to) => {
      const route = "/login?path=time-tracking/:id/time-tracker/clock-it";
      return route;
    },
    children: []
  },

  {
    path: "/logout",
    name: "logout",
    alias: "/logout",
    component: () => "",
    meta: { auth: false },
    beforeEnter: (_to, _from, next) => {
      logout();
      next("/login");
    }
  },
  {
    path: "/login",
    name: "login",
    alias: "/login",
    component: () =>
      /* webpackChunkName: "sign-in" */ (window.location.href = `${process.env.VUE_APP_SSO}/login?path=time-tracking/:id/time-tracker/clock-it`),
    meta: { auth: false }
  },
  {
    path: "/myglobalcomponentsarehere",
    name: "ShowGlobal",
    component: () =>
      import(/* webpackChunkName: "global" */ "@/ui-kit/GlobalComponents.vue")
  },

  {
    path: "/legacy",
    name: "legacy",
    component: () =>
      (window.location.href = `${
        process.env.VUE_APP_ERP_API
      }/auth?token=${getToken()}`),
    //  import(/* webpackChunkName: "legacy" */ "@/apps/HomePage.vue"),
    meta: { auth: true },
    beforeEnter: (_to, _from, next) => {
      if (isLoggedIn()) {
        window.location.href = `${
          process.env.VUE_APP_ERP_API
        }/auth?token=${getToken()}`;
      } else {
        next();
      }
    }
  },
  {
    path: "/time-tracking/:id/time-tracker/clock-it",
    name: "clock-it",
    beforeEnter: (to, _from, next) => {
      if (to.query["app-token"]) {
        const appToken = to.query["app-token"];
        const decodedToken = decodeRouteToken(appToken as string);
        const loginHint = to.query["login-hint"] as string;
        const id = encodeUrlPath(String(decodedToken?.user_id));
        const route = `/time-tracking/${id}/time-tracker/clock-it`;
        setToken(appToken as string);
        useStore()?.setUser(decodedToken);
        useStore()?.setUsersHint(loginHint);
        next(route);
      } else {
        next();
      }
    },
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "clock-it" */ "@/apps/time-tracker/components/timeTrackerComponents/ClockIt.vue"
      )
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../apps/authentication/components/ResetPassword.vue"
      )
  },
  {
    path: "/user-profile",
    name: "user-profile",
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal`)
  },
  {
    path: "/request-loan",
    name: "request-loan",
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal/loans`)
  },

  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { auth: true }
  }

  /** End of Auth routes */
];

const env = process.env;

export const routes = route.concat(timeTrackerRoute);

// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, _from, next) => {
  document.title = "Clock It";
  if (to.meta.auth && to.query["app-token"]) {
    next();
  } else if (to.meta.auth && !isLoggedIn()) {
    const redirect = `${window.location.protocol}//${window.location.host}`;
    window.location.href = `${env.VUE_APP_MAIN_PAGE_URL}sso-login?redirect-to=${redirect}`;
  } else {
    next();
  }
});
export default router;
